<template>
  <v-menu
    v-model="editTagsModal"
    :close-on-content-click="false"
    content-class="edit-tags"
    z-index="10"
    offset-y>
    <template #activator="{ on }">
      <span
        class="sourcery__icon-wrapper white"
        data-test="add_tags_button"
        v-on="on"
        @click="$emit('editTags')">
        <v-icon
          color="black">
          mdi-plus
        </v-icon>
      </span>
    </template>

    <v-card>
      <div class="d-flex flex-wrap gap-2">
        <v-chip
          v-for="(tag, index) in projectTags"
          :key="index"
          close-icon="mdi-close"
          color="black"
          close
          label
          outlined
          x-small
          @click:close="manageTags(tag, 'detachProjectTag')">
          {{ tag }}
        </v-chip>
      </div>
      <v-text-field
        v-model="enteredTag"
        autocomplete="off"
        class="mt-4"
        placeholder="Add new tag"
        prepend-inner-icon="mdi-tag-outline"
        dense
        hide-details
        outlined
        single-line
        @keyup.enter="addNewTag" />
      <p
        v-if="enteredTag"
        class="fs-11 mt-1 mb-0 darkGrey--text">
        Press ENTER to add "{{ enteredTag }}"
      </p>
      <div
        data-test="tag_block"
        class="mt-2">
        <v-checkbox
          v-for="item in diffWithAllTags"
          :key="item"
          :ripple="false"
          :label="item"
          class="mt-0"
          color="blue"
          hide-details
          @change="manageTags(item, 'attachProjectTag')" />
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import ProjectTagsApi from '@/services/graphql/projectTags';
import {
  mapMutations,
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
export default {
  name: 'EditTagsContainer',
  props: {
    projectTags: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    enteredTag: '',
    editTagsModal: false,
  }),
  computed: {
    ...mapGetters('ProjectDetailsTableSchedule', ['allTags']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    diffWithAllTags() {
      return this.lodash.differenceWith(this.allTags, this.projectTags, this.lodash.isEqual);
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapMutations('ProjectDetailsTableSchedule', ['setProjectTags']),
    ...mapActions(['handleError']),
    /**
     * add new tag when entered in the text field
     */
    addNewTag() {
      const { allTags, enteredTag } = this;

      if (!allTags.includes(enteredTag)) {
        this.setProjectTags([
          ...allTags,
          enteredTag,
        ]);
      }

      this.manageTags(enteredTag, 'attachProjectTag');
    },
    /**
     * Add or remove tag from the project
     * @param {String} tagName name of the tag
     * @param {String} criteria possible values - [`changeProjectTag`, `detachProjectTag`]
     */
    async manageTags(tagName, criteria) {
      if (!tagName) return;

      const data = {
        tagName,
        projectId: this.projectId,
        workspaceId: this.activeWorkspaceId,
      };
      try {
        this.spinner(true);
        await ProjectTagsApi.manageTags(data, criteria);

        if (criteria === 'detachProjectTag') {
          const tagsCopy = [...this.projectTags];
          tagsCopy.splice(tagsCopy.indexOf(tagName), 1);
          this.$emit('changeProjectTags', tagsCopy);
        } else {
          this.enteredTag = '';
          this.$emit('changeProjectTags', [...this.projectTags, tagName]);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-menu__content.edit-tags {
  overflow: hidden;

  .v-card {
    padding: 16px;
    width: 320px;
    height: 310px;
    overflow: auto;
  }
}
</style>
