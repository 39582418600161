<template>
  <div
    v-if="activeTagName"
    class="active-staring-filter d-flex justify-space-between align-center my-3">
    <div class="d-flex gap-2">
      <span>
        Project filtered by tag
        <v-chip
          color="black"
          class="fs-10 text-uppercase"
          text-color="white">
          {{ activeTagName }}
        </v-chip>
        Click the star to create a saved view
      </span>
      <v-icon
        class="icon-staring"
        @click="actionStarredView">
        {{ iconStar }}
      </v-icon>
    </div>
    <v-btn
      color="red"
      outlined
      rounded
      @click="setChangeActiveTabView(null)">
      Remove Filter
    </v-btn>
  </div>
</template>
<script>
import ProjectFilterTagsAPI from '@/services/graphql/projectFilterTags';
import {
  mapActions,
  mapGetters, mapMutations, mapState,
} from 'vuex';
export default {
  name: 'ActiveStaringFilterTagPanel',
  props: {
    activeTagName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      activeWorkspaceId: state => state.Workspace.activeWorkspaceId,
      staringFilteredViews: state => state.Projects.staringFilteredViews,
      activeStaringFilteredViewId: state => state.Projects.activeStaringFilteredViewId,
    }),
    ...mapGetters({
      getSortedItem: 'Projects/getSortedItem',
    }),
    getSortedMode() {
      return this.getSortedItem.sortingMode;
    },
    iconStar() {
      return this.isStaringFilterTag ? 'mdi-star' : 'mdi-star-outline';
    },
    isStaringFilterTag() {
      return this.activeStaringFilteredViewId;
    },
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      setActiveStaringFilteredViewId: 'Projects/setActiveStaringFilteredViewId',
      setStaringFilteredViews: 'Projects/setStaringFilteredViews',
      setChangeActiveTabView: 'Projects/setChangeActiveTabView',
    }),
    ...mapActions({
      handleError: 'handleError',
    }),
    actionStarredView() {
      this.isStaringFilterTag ? this.removedProjectListStarred() : this.setProjectListStarred();
    },
    async removedProjectListStarred() {
      try {
        this.spinner(true);
        const {
          activeWorkspaceId: workspaceId,
          activeStaringFilteredViewId: starredListId,
        } = this;
        const { data } = await ProjectFilterTagsAPI.deleteStarredProjectList({
          workspaceId,
          starredListId,
        });
        const { id = '' } = data.response || {
        };
        if (id) {
          const filterStaringViews = this.staringFilteredViews.filter(e => e.id !== id);
          this.setActiveStaringFilteredViewId('');
          this.setChangeActiveTabView({
            isDisabledWatch: true,
          });
          this.setStaringFilteredViews(filterStaringViews);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async setProjectListStarred() {
      try {
        if (this.isStaringFilterTag) return false;
        this.spinner(true);
        const {
          activeWorkspaceId: workspaceId,
          activeTagName: tags,
          getSortedMode: sortingMode,
        } = this;
        const { data } = await ProjectFilterTagsAPI.makeProjectListStarred({
          workspaceId,
          tags,
          sortingMode,
        });
        this.setActiveStaringFilteredViewId(data.response.id);
        this.setStaringFilteredViews([...this.staringFilteredViews, data.response]);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.active-staring-filter {
  .v-btn {
    height: unset !important;
  }
}
</style>
