<template>
  <div class="sourcery__container pt-16">
    <v-skeleton-loader type="sentences" />

    <div class="sourcery__grid mt-6">
      <v-sheet
        v-for="_ in 4"
        :key="_"
        color="lightGrey"
        class="pa-4"
        rounded>
        <v-skeleton-loader
          type="sentences" />

        <v-skeleton-loader
          class="mt-4"
          type="heading" />

        <v-skeleton-loader
          class="mt-2 bg-transparent"
          type="list-item-avatar-two-line" />
      </v-sheet>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsListSkeleton',
};
</script>

<style lang="scss" scoped>
.bg-transparent {
  ::v-deep .v-skeleton-loader__list-item-avatar-two-line {
    background: transparent !important;
  }
}
</style>