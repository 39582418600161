<template>
  <div class="projects-view d-flex flex-column">
    <template v-if="isProjectsList && !networkError">
      <v-text-field
        v-model="searchProjects"
        class="sourcery__search-bar"
        color="black"
        placeholder="Search your projects..."
        hide-details
        single-line />

      <div class="sourcery__container">
        <div class="d-flex flex-wrap flex-md-nowrap align-center justify-space-between gap-3 py-1">
          <span class="fs-24 black--text font-fam-poppins">{{ identificationWS }}</span>

          <div class="d-flex align-center gap-3">
            <FilterByTagsContainer
              @manageGroupTags="manageGroupTags" />
            <AppSort
              :default-item="getSortedItem"
              :sort-items="PROJECT_SORT_ITEMS"
              @resort="resortProjects" />
            <AppSelect
              :items="statusList"
              @resort="status = $event.name" />
          </div>
        </div>

        <ActiveStaringFilterTagPanel :active-tag-name="groupByTags.active.tag" />

        <div class="sourcery__grid mt-6">
          <ProjectsCard
            v-for="(item, index) in filteredProjects"
            :key="item.number + item.projectName + index"
            :item="item"
            @deleteProject="onProjectDelete({
              id: $event, status: status
            })"
            @updateProject="updateProject"
            @updateStatus="updateStatus" />
        </div>

        <div
          v-if="!filteredProjects.length"
          class="mt-16 fs-18 font-weight-light text-center">
          No matching projects found!
        </div>
      </div>

      <AppAddButtonNoMenu
        :color-tooltip="disabledPersonalWs ? 'red' : ''"
        :disabled="!canCreateProject.allowed"
        :access="canCreateProject"
        :click-callback="onCreateNewProject" />
    </template>

    <ProjectsListSkeleton v-else-if="showSkeleton" />

    <AppEmptyList
      v-else-if="showEmptyProjectsBlock"
      class="pt-32"
      from="projects" />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

import {
  PERSONAL_WORKSPACE_ID, ARCHIVED, ACTIVE,
} from '@/constants';
import { PROJECT_SORT_ITEMS } from '@/constants/sortable';

import ActiveStaringFilterTagPanel from '@/components/Projects/ProjectsTable/ProjectsTableStructure/ActiveStaringFilterTagPanel';
import AppAddButtonNoMenu from '@/components/App/AppAddButtonNoMenu';
import AppEmptyList from '@/components/App/AppEmptyList';
import AppSelect from '@/components/App/AppSelect';
import AppSort from '@/components/App/AppSortV2';
import FilterByTagsContainer from '@/components/Projects/ProjectsTable/ProjectsTableStructure/FilterByTagsContainer';
import ProjectsCard from '@/components/Projects/ProjectsCard';
import ProjectsListSkeleton from '@/components/Projects/ProjectsListSkeleton';

import RequestAbortController from '@/utils/RequestAbortController';

export default {
  name: 'Projects',
  components: {
    ActiveStaringFilterTagPanel,
    AppAddButtonNoMenu,
    AppEmptyList,
    AppSelect,
    AppSort,
    FilterByTagsContainer,
    ProjectsCard,
    ProjectsListSkeleton,
  },
  data: function () {
    return {
      PROJECT_SORT_ITEMS,
      ACTIVE,
      ARCHIVED,
      searchProjects: '',
      status: 'active',
      statusList: [{
        name: ACTIVE,
      }, {
        name: ARCHIVED,
      }],
    };
  },
  computed: {
    ...mapGetters('Projects', ['getSortedItem', 'sortedActiveProjects', 'sortedArchivedProjects']),
    ...mapGetters('Workspace', ['disabledPersonalWs']),
    ...mapGetters('UserRoles', ['canCreateProject']),
    ...mapGetters('UserProfile', ['getName']),
    ...mapState(['showSpinner', 'totalSubscription', 'networkError']),
    ...mapState('Projects', ['active', 'archived', 'isFinishedGetOfProjects', 'groupByTags']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    filteredProjects() {
      let { searchProjects, sortedActiveProjects, sortedArchivedProjects, status } = this;
      searchProjects = searchProjects.toLowerCase();

      const finalList = status === ACTIVE ? sortedActiveProjects : sortedArchivedProjects;

      return searchProjects === ''
        ? finalList
        : finalList.filter(({ projectName, number }) => {
          let pName = projectName.toLowerCase();
          let num = number.toLowerCase();
          return pName.includes(searchProjects) || num.includes(searchProjects);
        });
    },
    identificationWS() {
      if (this.activeWorkspaceId !== PERSONAL_WORKSPACE_ID) {
        return `Hey ${this.getName}, let's get building!`;
      }
      return `Welcome, ${this.getName}!`;
    },
    isFetchingProjectsList() {
      return this.$store.state.Projects.isFetching.projectsList;
    },
    isProjectsList() {
      const isActiveSortTrigger = this.groupByTags?.active?.groupByTagsTrigger;
      const isArchivedSortTrigger = this.groupByTags?.archived?.groupByTagsTrigger;
      return isActiveSortTrigger || isArchivedSortTrigger || this.active?.length || this.archived?.length;
    },
    showEmptyProjectsBlock() {
      const { isProjectsList, showSpinner, isFinishedGetOfProjects, networkError } = this;
      return !isProjectsList && !showSpinner && isFinishedGetOfProjects || networkError;
    },
    showSkeleton() {
      return this.isFetchingProjectsList;
    },
  },
  created() {
    this.abortController = new RequestAbortController();
    this.getData({
      abortController: this.abortController,
    });
    this.getAllProjectTags();
    this.subscribeCommentsAmount('subscribeTotalUnreadComments');
  },
  beforeDestroy() {
    this.totalSubscription.unsubscribe();
    this.clearData();
    this.abortController.abort();
  },
  methods: {
    ...mapActions('Comments', ['subscribeCommentsAmount']),
    ...mapActions('Projects', [
      'getData',
      'resortProjects',
      'clearData',
      'updateStatus',
    ]),
    ...mapActions('ProjectDetailsTableSchedule', ['getAllProjectTags']),
    ...mapMutations(['spinner']),
    ...mapMutations({
      setGroupByTags: 'Projects/setGroupByTags',
      setProjectsList: 'Projects/setProjectsList',
    }),
    manageGroupTags(tagItems) {
      const { tag, groupByTagsTrigger } = tagItems;
      const payload = {
        groupByTagsTrigger,
        status: ACTIVE,
        tag,
      };
      this.setGroupByTags(payload);
    },
    onCreateNewProject() {
      this.$store.commit('showModalAction', 'showCreateProjectModal');
    },
    onProjectDelete({ id, status }) {
      const newArr = this[status].filter(item => item.id !== id);
      this.setProjectsList({
        status,
        projects: newArr,
      });
    },
    updateProject(data) {
      const { status, id } = data;
      const projects = this[status].map(item => {
        return item.id === id ? data : item;
      });
      this.setProjectsList({
        status,
        projects,
      });
    },
  },
};
</script>
