<template>
  <div class="d-flex">
    <v-menu
      :nudge-bottom="5"
      offset-y>
      <template
        v-slot:activator="{ on, attrs }">
        <img
          src="@/assets/icons/search-filter-icon.svg"
          height="24"
          width="24"
          v-bind="attrs"
          v-on="on">
      </template>
      <v-list
        v-if="allTags.length">
        <v-list-item
          v-for="(tag, index) in allTags"
          :key="index"
          :class="{'v-item--active v-list-item--active': tag == getActiveTagByStatus }"
          @click="changeTags(tag, true)">
          <v-list-item-title>
            {{ tag }}
          </v-list-item-title>
        </v-list-item>
        <div
          class="pa-4">
          <v-btn
            :disabled="!groupByTags[status].tag"
            color="blue"
            class="white--text"
            @click="changeTags('', false)">
            Remove Filter
          </v-btn>
        </div>
      </v-list>
      <div
        v-else
        class="pa-4">
        <span>No tags available</span>
      </div>
    </v-menu>
  </div>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
export default {
  name: 'FilterByTagsContainer',
  props: {
    status: {
      type: String,
      default: 'active',
    },
  },
  computed: {
    ...mapGetters('ProjectDetailsTableSchedule', ['allTags']),
    ...mapState('Projects', ['changeActiveTabView', 'groupByTags']),
    getActiveTagByStatus() {
      return this.groupByTags[this.status]?.tag;
    },
  },
  watch: {
    changeActiveTabView: {
      deep: true,
      handler(val) {
        if (val?.isDisabledWatch) return false;
        const tag = !val ? '' : val.tags?.[0];
        const groupByTagsTrigger = Boolean(tag);
        this.manageGroupTags(tag, groupByTagsTrigger);
      },
    },
  },
  methods: {
    ...mapActions({
      setActiveViewIdByTags: 'Projects/setActiveViewIdByTags',
    }),
    ...mapMutations({
      setChangeActiveTabView: 'Projects/setChangeActiveTabView',
    }),
    changeTags(tag, groupByTagsTrigger) {
      this.setChangeActiveTabView({
        isDisabledWatch: true,
      });
      this.manageGroupTags(tag, groupByTagsTrigger);
    },
    manageGroupTags(tag, groupByTagsTrigger) {
      this.setActiveViewIdByTags(tag);
      this.$emit('manageGroupTags', {
        tag,
        groupByTagsTrigger,
      });
    },
  },
};
</script>
